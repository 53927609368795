<template>
  <div>
    <div class="content-wrapper">
      <div class="content-body">
        <div class="card">
          <div class="card-body">
    <div class=""><ul role="tablist" class="nav nav-pills" id="__BVID__291__BV_tab_controls_"><!----><li role="presentation" class="nav-item"><a role="tab" aria-selected="true" aria-setsize="1" aria-posinset="1" href="#" target="_self" class="nav-link active" id="__BVID__292___BV_tab_button__" aria-controls="__BVID__292"><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mr-0 mr-sm-50 feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg><span class="d-none d-sm-inline">User Information</span></a></li><!----></ul></div>

    <validation-observer
          ref="editUserForm"
    >
    <!-- User Info: Input Fields -->
    <b-form
    @submit.prevent="editUser"
    >
      <b-row>
        <!-- Field: Kota -->
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="FullName"
            rules="required"
          >
          <b-form-group
            label="FullName"
            label-for="FullName"
          >
            <b-form-input
              id="FullName"
              v-model="FullName"
            />
          </b-form-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
          </validation-provider>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required"
          >
          <b-form-group
            label="Email"
            label-for="Email"
          >
            <b-form-input
              id="Email"
              v-model="Email"
            />
          </b-form-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
          </validation-provider>
        </b-col>

        <!-- Field: Phone -->
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="PhoneNumber"
            rules="required"
          >
          <b-form-group
            label="Phone Number"
            label-for="PhoneNumber"
          >
            <b-form-input
              id="PhoneNumber"
              v-model="PhoneNumber"
            />
          </b-form-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
        <!-- Field: Gender -->
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Gender"
            rules="required"
          >
            <b-form-group
              label="Gender"
              label-for="Gender"
            >
              <v-select
                v-model="Gender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="genders"
                :reduce="val => val.value"
                :clearable="false"
                input-id="Gender"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Field: Branch -->
        <b-col
          cols="12"
          md="4"
        >
            <b-form-group
              label="Branch"
              label-for="BranchId"
            >
              <v-select
                v-model="BranchID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="branches"
                :reduce="val => val.value"
                :clearable="false"
                input-id="BranchId"
              />
            </b-form-group>
        </b-col>
        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
            <b-form-group
              label="Role"
              label-for="RoleID"
            >
              <v-select
                v-model="RoleID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roles"
                :reduce="val => val.value"
                :clearable="false"
                input-id="RoleID"
              />
            </b-form-group>
        </b-col>
      </b-row>
    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      type="submit"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Save Changes
    </b-button>
    </b-form>
    </validation-observer>
    <!-- <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button> -->
  </div>
  </div>
  </div>
  </div>
  </div>
</template>

<script>
import {
  BButton, BMedia, BRow, BCol, BAvatar, BFormGroup, BFormInput, BForm, BButtonGroup,
} from 'bootstrap-vue'
import axios from 'axios'
import { getToken } from '@/auth/utils'
import vSelect from 'vue-select'
import { required } from '@validations'
import { avatarText } from '@core/utils/filter'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const userToken = getToken()
const headers = {
  'Content-Type': 'application/json',
  'X-Token-Access': `Bearer ${userToken}`,
}
const genders = [
          { label: 'Pria', value: 'L' },
          { label: 'Wanita', value: 'P' },
        ]
const FullName = ''
const Email = ''
const Gender = ''
const BranchID = ''
const RoleID = ''
const UserID = ''
const PhoneNumber = ''
export default {
  components: {
    BButtonGroup,
    BButton,
    BMedia,
    vSelect,
    BRow,
    BCol,
    BAvatar,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
  },

  data() {
    return {
    avatarText,
      UserID,
      FullName,
      Email,
      Gender,
      BranchID,
      RoleID,
      PhoneNumber,
      roles: [],
      branches: [],
      genders,
      required,
    }
  },
  mounted() {
    const editData = JSON.parse(localStorage.getItem('userEdit'))
    this.UserID = editData.UserID
    this.FullName = editData.FullName
    this.Email = editData.Email
    this.Gender = editData.Gender === 'Pria' ? 'L' : 'P'
    this.BranchID = editData.BranchID
    this.RoleID = editData.UserRoleID
    this.PhoneNumber = editData.PhoneNumber
      this.getRoles()
      this.getBranches()
  },
  methods: {
      getRoles() {
          axios
              .get(process.env.VUE_APP_API_GET_ROLES, { headers })
              .then(response => {
                const rolesJoin = response.data.Payload.map(elem => (
                  { label: elem.UserRoleName, value: elem.UserRoleID }
                ))
                this.roles = rolesJoin
              })
              .catch(err => {
                  console.log(err)
              })
      },
      getBranches() {
          axios
              .get(process.env.VUE_APP_API_GET_BRANCH, { headers })
              .then(response => {
                const branchesJoin = response.data.Payload.map(elem => (
                { label: `${elem.Deskripsi} (${elem.KodeUnitKerja})`, value: elem.BranchID, kodeunit: elem.KodeUnitKerja }
                ))
                this.branches = branchesJoin.sort((a, b) => parseInt(a.kodeunit) > parseInt(b.kodeunit) ? 1 : -1)
              })
              .catch(err => {
                  console.log(err)
              })
      },
    editUser() {
      this.$refs.editUserForm.validate().then(success => {
        const payload = {
            UserID: this.UserID,
            Email: this.Email,
            FullName: this.FullName,
            PhoneNumber: this.PhoneNumber,
            Gender: this.Gender,
            RoleID: this.RoleID,
            BranchID: this.BranchID,
          }
          console.log(payload)
        if (success) {
          axios
          .post('https://api.geolims.com/user_service/edituser', payload, { headers })
          .then(response => {
              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              if (response.data.Status === 1) {
                this.$root.$emit('refreshTable', 'refreshTable')
                // this.$parent.refBranchListTable.refresh()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Your data has been saved!',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              }
              }).catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
